<template>
  <b-card class="mb-4 pt-4" header-tag="header">
    <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{ $route.name }}</h5>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" href="#/master-data/paket/create"
              >Tambah Data</b-btn
            >
          </b-button-group>
        </b-col>
      </b-row>
    </template>
    <b-row class="mb-3" style="margin-top: -20px;">
      <b-col cols="12">
        <b-form @submit.prevent="doFilter">
          <h6 class="mb-1" style="font-size: 1rem;">Filter Data</h6>
          <b-row class="filter-row">
            <b-col cols="3">
              <form-group-input
                ref="filter-name"
                type="text"
                placeholder=""
                :need-label="true"
                label="Nama Paket"
                v-model="filter.name"
                id="filter-name"
              />
            </b-col>
            <b-col cols="3">
              <form-group-input
                ref="filter-type"
                type="text"
                placeholder=""
                :need-label="true"
                label="Tipe Paket"
                v-model="filter.type"
                id="filter-type"
              />
            </b-col>
            <b-col cols="3">
              <b-form-group>
                <label for="" style="font-size:13px ;">Status</label>
                <b-form-select
                  v-model="filter.status"
                  :options="options.status"
                  style="position: relative;top: -7px;"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <label class="form-label">&nbsp;</label><br />
              <b-btn type="submit" variant="primary">Cari</b-btn>
              <b-btn
                type="button"
                @click.prevent="clearForm"
                variant="warning"
                class="ml-3 text-white"
                >Clear</b-btn
              >
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table
      ref="dataTable"
      aksi-dot
      :delete-reason="false"
      :can-delete="true"
      :can-edit="true"
      :data-url="apiUrl.Paket"
      :data-columns="columns"
      :dataParams="dataParams"
      :defaultDataParams="this.filter"
    >
      <template slot="content_is_active" slot-scope="defaultSlotScope">
        <div class="text-center">
          <h1
            :class="['badge', (defaultSlotScope.colData.is_active == 1) ? 'badge-v1-success' : 'badge-v1-danger'],"
          >
            {{
              defaultSlotScope.colData.is_active == 1 ? "Aktif" : "Tidak Aktif"
            }}
          </h1>
        </div>
      </template>
    </paginated-table>
  </b-card>
</template>

<script>
import paginatedTable from "@/components/PaginatedTable.vue";
import formGroupInput from "@/components/FormGroupInput.vue";
import Package from "@/models/Package.js";
// import { text } from 'body-parser'

const PackageModel = new Package();

export default {
  components: {
    formGroupInput,
    paginatedTable,
  },
  data() {
    return {
      isLoading: false,
      apiUrl: {
        Paket: PackageModel.endpoint,
      },
      columns: [
        {
          prop: "name",
          label: "Nama",
          sortable: true,
          resizable: true,
          minWidth: 200,
        },
        {
          prop: "type",
          label: "Tipe",
          sortable: true,
          resizable: true,
          minWidth: 200,
        },
        {
          prop: "min_dp",
          label: "Min. DP",
          needCurrency: true,
          resizable: true,
          sortable: false,
          align: "right",
          minWidth: 100,
        },
        {
          prop: "seat",
          label: "Batas Kursi",
          resizable: true,
          sortable: false,
          align: "center",
          minWidth: 100,
        },
        {
          prop: "seat_available",
          label: "Kursi Tersedia",
          resizable: true,
          sortable: false,
          align: "center",
          minWidth: 100,
        },
        {
          prop: "category",
          label: "Categori",
          resizable: true,
          sortable: false,
          align: "left",
          minWidth: 100,
        },
        {
          prop: "regency",
          label: "Lokasi",
          resizable: true,
          sortable: false,
          minWidth: 100,
        },
        {
          prop: "price",
          label: "Harga",
          align: "right",
          resizable: true,
          sortable: false,
          needCurrency: true,
          minWidth: 100,
        },
        {
          prop: "discount",
          label: "Diskon",
          align: "right",
          resizable: true,
          sortable: false,
          minWidth: 100,
        },
        {
          prop: "is_active",
          label: "Status",
          align: "center",
          resizable: true,
          sortable: false,
          minWidth: 100,
        },
      ],
      options: {
        status: [
          { value: "Aktif", text: "Aktif" },
          { value: "Tidak Aktif", text: "Tidak Aktif" },
        ],
      },
      filter: {
        status:
          typeof this.$route.query.status !== "undefined"
            ? this.$route.query.status
            : null,
        type:
          typeof this.$route.query.type !== "undifined"
            ? this.$route.query.type
            : null,
        name:
          typeof this.$route.query.name !== "undefined"
            ? this.$route.query.name
            : null,
        with_category: 1,
        with_regency_detail: 1,
      },

      dataParams: [
        "name",
        "with_category",
        "with_regency_detail",
        "type",
        "is_active",
        "status",
      ],
    };
  },
  beforeMount() {
    this.$router.push({ query: { ...this.$route.query } }).catch((err) => {});
  },
  mounted() {},
  watch: {},
  methods: {
    clearForm() {
      this.$nextTick(() => {
        this.filter = {
          name: null,
          status: null,
          is_active: null,
        };
        setTimeout(() => {
          this.doFilter();
        }, 100);
      });
    },
    doFilter() {
      this.$refs.dataTable.doFilter(this.filter);
    },
  },
};
</script>
